import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';

import { localizePath } from '../../utils/localize';
import CustomLink from '../CustomLink';

import useInfoBlogData from '../../queries/infoBlogQueries';

import './informationArticle.scss';
import './heroCategory.scss';

const BlogCategory = (props) => {
  const [active, setActive] = useState(null);
  const { pageContext } = props;
  const infoBlogsData = useInfoBlogData();
  const langInfoBlogsData = infoBlogsData.filter(
    (item) => item.locale === props.data.locale,
  );

  const sortBlogsByDate = props.data.blogs.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  return (
    <div className="notification-container">
      <div className="card hero-container border-0 text-white">
        <img
          src={langInfoBlogsData[0].categoryHero.image.url}
          className="card-img"
          alt="..."
        />
        <div className="card-img-overlay mx-auto col-lg-8">
          <h5 className="card-title hero-title-one">
            {langInfoBlogsData[0].categoryHero.title}
          </h5>
          <p className="card-text hero-title-two d-none d-lg-block mt-4">
            {langInfoBlogsData[0].categoryHero.description}
          </p>
          {/* visible on mobile */}
          <p className="card-text hero-title-two-mobile d-lg-none mt-3">
            {langInfoBlogsData[0].categoryHero.description}
          </p>
        </div>
      </div>
      <div className="main-wrapper col-lg-8 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-3 col-12 py-lg-5">
          <div className="bg-transparent button-wrapper">
            {langInfoBlogsData.map((item) => (
              <CustomLink
                className="language-text"
                link={{
                  ...item,
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: item.slug,
                    locale: item.locale,
                  })}`,
                }}
                to={`/${item.slug}`}
              >
                <button
                  className={` py-3 px-2 link-button ${
                    active === item && 'active-link'
                  }`}
                  onClick={() => setActive(item)}
                  type="button"
                  key={item.id}
                >
                  {item.title}
                </button>
              </CustomLink>
            ))}
          </div>
        </div>
        <div className="right-blog-container-wrapper h-auto py-lg-3 ms-lg-2 col-lg-9 col-xl-8 col-12">
          <div className="main-container col-12 col-lg-11 mx-auto mt-lg-4">
            <h3 className="blog-article-title col-11">{props.data.title}</h3>
            <div className="text-blog-container h-auto col-12 col-lg-10  col-md-11 mx-auto">
              {sortBlogsByDate.map((item) => (
                <div className="mb-3 h-auto bg-light p-3" key={item.id}>
                  <CustomLink
                    className="language-text"
                    link={{
                      ...item,
                      url: `${localizePath({
                        ...pageContext,
                        isPreview: false,
                        slug: item.slug,
                        locale: item.locale,
                      })}`,
                    }}
                    to={`/${item.slug}`}
                  >
                    <div className="d-md-flex">
                      <img
                        className="col-12 d-none d-md-block col-md-5 col-lg-3 me-2 pt-5"
                        src={item?.image?.url}
                        alt=""
                      />
                      <h3 className="col-12 col-md-7 mx-auto sm:mt-5">
                        {item.title}
                      </h3>
                    </div>
                    <p className="description mt-4">
                      <Moment format="DD.MM.YYYY">{item.date}</Moment>
                    </p>
                    <ReactMarkdown className="main-content-text mt-4">
                      {`${item?.content?.substring(0, 225)}...`}
                    </ReactMarkdown>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BlogCategory.propTypes = {
  data: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    blogs: PropTypes.arrayOf({
      text: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default BlogCategory;
