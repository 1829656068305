/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import InformationArticles from '../components/InformationArticles';
import SEO from '../components/seo';

const InformationsPage = ({ data, pageContext }) => {
  const { metadata, localizations } = data.strapiInfoBlogs;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <InformationArticles data={data.strapiInfoBlogs} />
      </Layout>
    </>
  );
};

export default InformationsPage;

export const query = graphql`
  fragment globalInfoBlogsData on StrapiGlobal {
    footer {
      ETFDepartments {
        title
        id
      }
      ETFDepartmentLinks {
        url
        title
        id
      }
      externLinks {
        id
        title
        url
      }
      address {
        title
        Description
        description
      }
      contact {
        Fax
        Email
        Tel
        title
      }
      copyright {
        title
      }
      Social {
        id
        Link
      }
    }

    id
    metadata {
      metaDescription
      metaTitle
    }
    navbar {
      mainLinks {
        id
        url
        title
      }
      secondLinks {
        id
        title
        url
      }
      ETFDepartment {
        url
        title
      }
    }
  }
  query infoBlogPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiInfoBlogs(id: { eq: $id }) {
      infoBlogId
      locale
      localizations {
        id
        locale
      }
      slug
      strapiId
      title
      blogs {
        blogId
        categories
        category
        content
        date
        description
        id
        image {
          url
        }
        info_blog
        locale
        slug
        title
      }
      id
    }
  }
`;
