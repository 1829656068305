import { graphql, useStaticQuery } from 'gatsby';

const useInfoBlogData = () => {
  const {
    allStrapiInfoBlogs: { nodes: blog },
  } = useStaticQuery(graphql`
    {
      allStrapiInfoBlogs(sort: { order: ASC, fields: strapiId }) {
        nodes {
          id
          locale
          localizations {
            id
            locale
          }
          title
          infoBlogId
          slug
          categoryHero {
            description
            id
            title
            image {
              url
            }
          }
        }
      }
    }
  `);
  return blog;
};

export default useInfoBlogData;
